import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const About = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Typography
        color="primary"
        align="center"
        variant="h2"
        component="h1"
        sx={{ fontWeight: 700, letterSpacing: '.3rem' }}
      >
        Tradable Temporal Exchanges
      </Typography>
      <Container maxWidth="md" sx={{ marginTop: 4 }}>
        <Typography align="center" variant="h6" sx={{ fontWeight: 300 }}>
          Opthy is the next evolution in DEXs by modularizing the exchange
          experience into an NFT, scaling it down, and giving full control over
          to the users
        </Typography>
      </Container>
      <Box sx={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 2 }}>
        <Button variant="contained" size="large" href="https://docs.opthy.com">
          Get Started
        </Button>
      </Box>
    </Container>
  );
};

export default About;
