import * as React from 'react';
import type { HeadFC } from 'gatsby';
import Navbar from '../components/Navbar';
import About from '../components/About';
import Features from '../components/Features';
import Stakeholders from '../components/Stakeholders';
import Box from '@mui/material/Box';
import UseCases from '../components/UseCases';
import Footer from '../components/Footer';

const IndexPage = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          backgroundImage: 'url(/hero-background.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPositionX: { xs: '90%' },
        }}
      >
        <About />
        <Features />
      </Box>
      <Stakeholders />
      <Box
        sx={{
          backgroundImage: 'url(/bg-use-cases.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: { xs: 'cover', md: 'contain' },
          backgroundPositionX: '50%',
        }}
      >
        <UseCases />
      </Box>
      <Footer />
    </>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Home Page</title>;
