import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { StaticImage } from 'gatsby-plugin-image';

const useCases = [
  {
    icon: '',
    title: 'DeFi Power Users',
    desc: 'DeFi users can have peace of mind by protecting themselves from drastic devaluation of their crypto assets.',
  },
  {
    icon: '',
    title: 'Liquidity Providers',
    desc: 'Borrowers can buy insurance to protect their collateral from getting liquidated in a volatile market.',
  },
  {
    icon: '',
    title: 'Swappers',
    desc: 'Speculators can either buy insurance or provide liquidity by assessing the price movement of the crypto asset.',
  },
];

const UseCases = () => {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row', alignItems: 'center' },
          py: 16,
        }}
      >
        <Box sx={{ width: { xs: '100%', md: '50%' } }}>
          <Typography variant="h4" sx={{ fontWeight: 700 }}>
            Why Opthy?
          </Typography>
          <Typography
            paragraph
            sx={{
              marginTop: 1,
              marginBottom: 6,
              maxWidth: 450,
            }}
          >
            A Swapper is empowered to swap tokens at a fixed swap rate in the
            expectation that at least one of the tokens in the Opthy will
            increase in real value by the end of the contract. Because the fixed
            swap rate for tokens in an Opthy is fixed, changes in the actual
            global average prices of the tokens presents an awesome opportunity
            for traders needing to cover margin calls or major moves in market
            direction.
          </Typography>
          {/* {useCases.map((useCase) => (
            <Box>
              <Typography variant="h5" color="primary" sx={{ fontWeight: 700 }}>
                {useCase.title}
              </Typography>
              <Typography paragraph sx={{ maxWidth: 430 }}>
                {useCase.desc}
              </Typography>
            </Box>
          ))} */}
        </Box>
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <StaticImage
            placeholder="blurred"
            src="../images/dapp-preview.png"
            alt="DApp preview"
          />
        </Box>
      </Box>
    </Container>
  );
};

export default UseCases;
