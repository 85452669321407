import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';

const Footer = () => {
  return (
    <Box sx={{ borderTop: '1px solid #545454' }}>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: 80,
          }}
        >
          <Typography>Reach out to us hello[at]opthy.com</Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box
              sx={{
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                padding: 0.5,
              }}
            >
              <GitHubIcon sx={{ color: 'black' }} />
            </Box>
            <Box
              sx={{
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                padding: 0.5,
              }}
            >
              <TwitterIcon sx={{ color: 'black' }} />
            </Box>
            <Box
              sx={{
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                padding: 0.5,
              }}
            >
              <TelegramIcon sx={{ color: 'black' }} />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
