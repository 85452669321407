import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomizedDivider from './CustomizedDivider';

const features = [
  { icon: '/icons/swap-rate.svg', label: 'Fixed Swap Rate' },
  { icon: '/icons/non-custodial.svg', label: 'Non-custodial' },
  {
    icon: '/icons/liquidity-params.svg',
    label: 'Configurable Liquidity Parameters',
  },
  { icon: '/icons/swapper.svg', label: 'Swapper' },
  { icon: '/icons/unlimited-swaps.svg', label: 'Unlimited Swaps' },
  { icon: '/icons/low-fees.svg', label: 'Low Fees' },
  { icon: '/icons/trustless.svg', label: 'Trustless' },
  {
    icon: '/icons/expiry-period.svg',
    label: 'Flexible Expiry Period',
  },
];

const Features = () => {
  return (
    <Container maxWidth="xl" sx={{ py: 8 }}>
      <Box
        sx={{ display: 'flex', gap: { xs: 3, md: 5 }, position: 'relative' }}
      >
        <CustomizedDivider orientation="vertical" flexItem />
        <Box>
          <Typography variant="h4" sx={{ fontWeight: 700 }}>
            Exclusive Features
          </Typography>
          <Typography
            paragraph
            sx={{
              marginTop: 1,
              maxWidth: 400,
            }}
          >
            Our vision is to empower all levels of crypto-asset users by
            providing easy and accessible tools in the form of configurable
            temporal exchanges.
          </Typography>
          <Box sx={{ display: 'flex', marginTop: 4, flexWrap: 'wrap' }}>
            {features.map((feature) => (
              <Box
                sx={{
                  p: '20px',
                  border: '1px solid #545454',
                  backgroundColor: '#040404',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                }}
              >
                <img
                  src={feature.icon}
                  height={25}
                  width={25}
                  placeholder="blurred"
                  alt=""
                />
                <Typography variant="body2">{feature.label}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Features;
