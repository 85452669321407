import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { StaticImage } from 'gatsby-plugin-image';
import CustomizedDivider from './CustomizedDivider';

const Stakeholders = () => {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          py: 9,
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            display: 'flex',
            justifyContent: 'center',
            px: { md: 4 },
          }}
        >
          <StaticImage
            placeholder="blurred"
            src="../images/crypto-assets.png"
            alt="Crypto assets"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            width: { xs: '100%', md: '50%' },
          }}
        >
          <CustomizedDivider orientation="vertical" flexItem />
          <Box sx={{ px: { md: 4 } }}>
            <Box sx={{ pl: { xs: 2, md: 0 } }}>
              <Typography variant="h4" sx={{ fontWeight: 700 }}>
                Protocol Stakeholders
              </Typography>
              <Typography
                paragraph
                sx={{
                  marginTop: 1,
                  maxWidth: 400,
                }}
              >
                Earn fees by creating Opthy & providing liquidity or swap
                between any assets freely at a fixed rate.
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', marginTop: 8.5 }}>
              <Box sx={{ mx: { xs: 2, md: 6 } }}>
                <StaticImage
                  layout="fixed"
                  height={62}
                  width={62}
                  src="../images/icons/stakeholder-swapper.svg"
                  alt=""
                />
              </Box>

              <Box>
                <Typography variant="h6" color="primary">
                  Swapper
                </Typography>
                <Typography paragraph sx={{ maxWidth: 400, color: '#929292' }}>
                  Swap tokens on the Opthy unlimited times at a fixed swap rate
                  for the specified duration of the contract
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', marginTop: 4 }}>
              <Box sx={{ mx: { xs: 2, md: 6 } }}>
                <StaticImage
                  layout="fixed"
                  height={62}
                  width={62}
                  src="../images/icons/stakeholder-lp.svg"
                  alt=""
                />
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  Liquidity Provider
                </Typography>
                <Typography paragraph sx={{ maxWidth: 400, color: '#929292' }}>
                  Create an Opthy by providing liquidity and earn fees from
                  swappers trade on your Opthy
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Stakeholders;
