import React from 'react';
import { Divider, styled } from '@mui/material';

const CustomizedDivider = styled(Divider)(
  ({ theme }) => `
  border: 1px solid white;
  border-image: linear-gradient(to top, #90CAF9, rgba(0, 0, 0, 0)) 1 100%;
  &:before {
    content: "";
    height: 28px;
    width: 28px;
    border: 1px solid #90CAF9;
    background-color: #000000;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    bottom: -9px;
    left: -13px;
  }
  &:after {
    content: "";
    height: 10px;
    width: 10px;
    background-color: #90CAF9;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    bottom: 0%;
    left: -4px;
  }
  @media (min-width: 0px) {
    margin-left: 8px;
    &:before {
      left: -5px;
    }
    &:after {
      left: 4px;
    }
  }
  @media (min-width: 900px) {
    margin-left: 0px;
    &:before {
      left: -13px;
    }
    &:after {
      left: -4px;
    }
  }
  `
);

export default CustomizedDivider;
